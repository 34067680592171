import "./media.css";
import React, { useState, useEffect } from "react";
import { getRequest } from "../../../functions/request";
import { CardActionArea, CardContent, Link, Modal, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { Carousel } from 'react-responsive-carousel';
import { Card, Grid, Typography } from "@mui/material";
import { CustomTestimonialGrid } from "../../../assets/css/Custom/CustomTestimonialGrid";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { border, justifyItems, zIndex } from "styled-system";
import { data } from "../../Home/CurrentAchievement/data";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CloseIcon from '@mui/icons-material/Close';
import { CenterFocusStrong } from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


var settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
};
// content

// const object = [
//   {
//     content:
//       "A comprehensive training programme on ‘Smart Distribution System and Storage’ has commenced at the Indian Institute of Technology Kanpur (IIT-K).",
//     img: "#",
//     title: "Training program on challenges in power sector starts at IIT-K",
//     date: "24 August 2023",
//     link: "#",
//   },
//   {
//     content:
//       "The Indian Institute of Technology Kanpur (IITK) is gearing up for its annual sports festival, Udghosh 2023 and in order to bring the festival closer to school students, the institute has announced Udghosh National Open School Quiz (UNOSQ) for students from classes 5 to 12.",
//     img: "#",
//     title: "IIT Kanpur to organise Udghosh National Open School Quiz",
//     date: "24 August 2023",
//     link: "#",
//   },
// ];

function NewsRenderer({
  title,
  description,
  links,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const arr = links.split(",");

  return (
    <Box
      xs={12}
      sm={8}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "left",
        justifyContent: "space-evenly",
        overflowY: "auto",
      }}
      padding={1}
      marginTop={0}
    >
      <Card style={{ width: "100%" }}>
        <CardActionArea style={{ maxWidth: "100%" }} onClick={handleOpen}>
          <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                cursor: 'pointer',
                width: { xs: '100%', sm: '250px', md: '200px', lg: '450px' },
                height: { xs: '100%', sm: '250px', md: '200px', lg: '300px' },
                margin: { xs: '10px auto', sm: '10px' },
              }}
            >
              <img
                src={`https://nitjsr.ac.in/backend/${arr[0]}`}
                alt="Thumbnail"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  padding: '10px',
                  '&:hover': {
                    opacity: 1,
                    alignItems: 'center',
                  },
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    color: 'white',
                    transform: 'translateY(18%)',
                    transition: 'transform 0.5s ease',
                    fontFamily: "Poppins",
                    textAlign:'center',
                    fontSize:{xs:'1rem',md:'1.4rem'}
                  }}
                >
                  {description}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'relative',
            // display:'flex',
            // flexDirection:'row',
            // justifyContent:'center',
            // alignItems:'center',
            maxWidth: { xs: '100%', sm: '90%', md: '70%', lg: '50%' },
            height: '80%',
            margin: 'auto',
            marginTop: '50px',
            bgcolor: 'background.paper',
            padding: 2,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: { xs: '8%', sm: '8%', md: '10%', lg:'10%' },
              right: '-1%',
              color: 'black',
              zIndex:'50'
            }}
          >
            <CloseIcon sx={{color:'white', background:'#0000008c', fontSize:'2rem', borderRadius:'50%', padding:'5px'}}/>
          </IconButton>
          <Carousel
            showThumbs={false}
            dynamicHeight={false}
            showIndicators={false}
            renderArrowNext={(clickHandler, hasNext) => (
              hasNext && (
                <IconButton
                  onClick={clickHandler}
                  sx={{
                    position: 'fixed',
                    top: '50%',
                    right: { xs: '2%', sm: '5%', md: '10%', lg: '20%' },
                    transform: 'translateY(-50%)',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              )
            )}
            renderArrowPrev={(clickHandler, hasPrev) => (
              hasPrev && (
                <IconButton
                  onClick={clickHandler}
                  sx={{
                    position: 'fixed',
                    top: '50%',
                    left: { xs: '2%', sm: '5%', md: '10%', lg: '20%' },
                    transform: 'translateY(-50%)',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )
            )}
          >
            {arr.map((link, index) => (
              <div key={index}>
                <img
                  src={`https://nitjsr.ac.in/backend/${link}`}
                  alt={`Image ${index}`}
                  style={{
                    marginTop: '30px',
                    padding:'20px',
                    marginBottom:'20px',
                    // maxWidth: '80%',
                    maxHeight: '85vh',
                    objectFit: 'contain',
                    // width: '50%',
                    // height: '50%',
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </Box>
  );
}

function transformData(data) {
  const result = {};

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Iterate through the data
  data.forEach((item) => {
    const date = new Date(item.published_date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is zero-based, so add 1 to get the actual month
    const monthName = monthNames[month - 1]; // Subtract 1 to get the correct index

    if (!result[year]) {
      result[year] = {};
    }
    if (!result[year][monthName]) {
      result[year][monthName] = [];
    }

    result[year][monthName].push({
      title: item.title,
      description: item.description,
      source_link: item.source_link,
      published_date: date.toDateString(),
      links: item.links,
    });
  });

  return result;
}

function Media() {
  const { t } = useTranslation();
  const [d, setD] = useState([{}]);
  const [title, setTitle] = useState({});
  const [image, setImage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRequest(`/media-coverage/fetch`, false);
        console.log(res.data);

        const data = transformData(res.data);
        //console.log(data);
        setD(data);
        // let a = {};
        // let b = [];
        // for (let year in data.keys()) {
        //   for (let month in data[year].keys()) {
        //     a[(year, month)].push(data[year][month].title);
        //   }
        // }
        // console.log(a);
        // setTitle(a);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  //  When we will get the destination api to hit for the news them instead of sample object[] array we will use the Notices[] array
  return (
    <div className="wrapper">
      <Card
        sx={{
          borderRadius: "12",
          py: "1rem",
          px: "2rem",
        }}
      >
        {Object.keys(d).map((year) => (
          <Accordion key={year}
          sx={{boxShadow:'none'}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreRoundedIcon style={{ color: 'white', fontSize: '2rem' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                fontSize: "1.5em",
                marginBottom: "10px",
                marginTop: "10px",
                fontWeight: "700",
                background: "#274187",
                color: "#fff",
                // padding: "1% 1% 1% 3%",
              }}
            >
              <Typography sx={{ color: "#fff", fontSize: "1.2rem", fontWeight:'600', fontFamily: "Poppins" }}>
                {year}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {Object.keys(d[year]).map((month) => (
                <Accordion
                sx={{boxShadow:'none'}}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreRoundedIcon style={{ color:"#274187", fontSize: '2rem' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      fontSize: "1.2em",
                      marginBottom: "8px",
                      marginTop: "8px",
                      fontWeight: "500",
                      background: "#eef3fd",
                      // color: "#fff",
                      // padding: "1% 1% 1% 3%",
                    }}
                  >
                    <Typography sx={{ color: "#274187", fontSize: "1.1rem",fontWeight:'500', fontFamily: "Poppins" }}>
                      {month}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Typography fontSize="1.8rem"
                    sx={{ color: "#000", fontSize: "1.1rem", fontFamily: "Poppins", marginBottom:'10px' }}
                    >
                      <ul>
                        {d[year][month].map((item) => (
                          <li>{item.title}</li>
                        ))}
                      </ul>
                    </Typography>

                    {d[year][month].map((props) => (
                      <NewsRenderer
                        {...props}
                      // published_date={data[year][month].published_date}
                      // key={`${year}+${month}`}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </div>
  );
}
export default Media;
